<script setup lang="ts">
    import type { NuxtError } from '#app';

    defineProps({
        error: Object as () => NuxtError,
    });

    const closeWindow = () => window.close();
</script>

<template>
    <div
        class="flex items-center justify-center h-screen bg-red-700 text-white flex-col gap-4"
    >
        <section class="flex flex-col gap-2 items-start">
            <p class="font-bold text-6xl">STOP.</p>
            <!-- <p class="font-bold text-6xl">Error {{ error?.statusCode }}</p> -->
            <small>{{ error.statusMessage ?? error?.cause }}</small>
            <button @click="closeWindow" class="btn mt-4">Return to POS</button>
        </section>
    </div>
</template>
