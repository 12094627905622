import { default as confirmqkqxgiK386Meta } from "/opt/buildhome/repo/pages/confirm.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as shippingFRNo84PUVxMeta } from "/opt/buildhome/repo/pages/shipping.vue?macro=true";
export default [
  {
    name: confirmqkqxgiK386Meta?.name ?? "confirm",
    path: confirmqkqxgiK386Meta?.path ?? "/confirm",
    meta: confirmqkqxgiK386Meta || {},
    alias: confirmqkqxgiK386Meta?.alias || [],
    redirect: confirmqkqxgiK386Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: shippingFRNo84PUVxMeta?.name ?? "shipping",
    path: shippingFRNo84PUVxMeta?.path ?? "/shipping",
    meta: shippingFRNo84PUVxMeta || {},
    alias: shippingFRNo84PUVxMeta?.alias || [],
    redirect: shippingFRNo84PUVxMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/shipping.vue").then(m => m.default || m)
  }
]