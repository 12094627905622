
// @ts-nocheck


export const localeCodes =  [
  "en",
  "da",
  "pl",
  "is",
  "se",
  "no",
  "lt"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts?hash=c6112a2a&locale=en" /* webpackChunkName: "locale__opt_buildhome_repo_lang_en_ts" */), cache: false }],
  "da": [{ key: "../lang/da.ts", load: () => import("../lang/da.ts?hash=3488d09e&locale=da" /* webpackChunkName: "locale__opt_buildhome_repo_lang_da_ts" */), cache: false }],
  "pl": [{ key: "../lang/pl.ts", load: () => import("../lang/pl.ts?hash=50a1a432&locale=pl" /* webpackChunkName: "locale__opt_buildhome_repo_lang_pl_ts" */), cache: false }],
  "is": [{ key: "../lang/is.ts", load: () => import("../lang/is.ts?hash=4d3be471&locale=is" /* webpackChunkName: "locale__opt_buildhome_repo_lang_is_ts" */), cache: false }],
  "se": [{ key: "../lang/se.ts", load: () => import("../lang/se.ts?hash=76fee81e&locale=se" /* webpackChunkName: "locale__opt_buildhome_repo_lang_se_ts" */), cache: false }],
  "no": [{ key: "../lang/no.ts", load: () => import("../lang/no.ts?hash=458acd52&locale=no" /* webpackChunkName: "locale__opt_buildhome_repo_lang_no_ts" */), cache: false }],
  "lt": [{ key: "../lang/lt.ts", load: () => import("../lang/lt.ts?hash=e8438a17&locale=lt" /* webpackChunkName: "locale__opt_buildhome_repo_lang_lt_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "files": [
        "lang/en.ts"
      ]
    },
    {
      "code": "da",
      "name": "Danish",
      "files": [
        "lang/da.ts"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "files": [
        "lang/pl.ts"
      ]
    },
    {
      "code": "is",
      "name": "Icelandic",
      "files": [
        "lang/is.ts"
      ]
    },
    {
      "code": "se",
      "name": "Swedish",
      "files": [
        "lang/se.ts"
      ]
    },
    {
      "code": "no",
      "name": "Norwegian",
      "files": [
        "lang/no.ts"
      ]
    },
    {
      "code": "lt",
      "name": "Lithuanian",
      "files": [
        "lang/lt.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "lang/en.ts"
      }
    ]
  },
  {
    "code": "da",
    "name": "Danish",
    "files": [
      {
        "path": "lang/da.ts"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "files": [
      {
        "path": "lang/pl.ts"
      }
    ]
  },
  {
    "code": "is",
    "name": "Icelandic",
    "files": [
      {
        "path": "lang/is.ts"
      }
    ]
  },
  {
    "code": "se",
    "name": "Swedish",
    "files": [
      {
        "path": "lang/se.ts"
      }
    ]
  },
  {
    "code": "no",
    "name": "Norwegian",
    "files": [
      {
        "path": "lang/no.ts"
      }
    ]
  },
  {
    "code": "lt",
    "name": "Lithuanian",
    "files": [
      {
        "path": "lang/lt.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
